import React, { useState } from "react";
import { Router } from "@reach/router";
import {
  PrivacySection,
  QuoteLayout,
  Thankyou,
} from "../../components/utility/quote";
import { Product_Quote_Routes } from "../../data/Routes";
import InternetContext from "../../contexts/internetContext";
import firebase from "gatsby-plugin-firebase";
import Question1 from "./Question1";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Form from "./Form";
import { ServicesMenuSection } from "../../components/containers/ServicesMenuSection";
import { Footer } from "../../components/utility";
import GtagCode from "../../data/GtagCode";

const Quote = () => {
  const [que1, setQue1] = useState(null);
  const [que2, setQue2] = useState(null);
  const [que3, setQue3] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const addLeadToBackend = async (values) => {
    await fetch("/.netlify/functions/sendgrid", {
      method: "POST",
      body: JSON.stringify({
        email: "hitesh.patel@icthub.com.au",
        subject: "New lead recived!!",
        name: values.fullname,
      }),
    });
    var timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.firestore().collection("Business_Internet").add({
      customerDetails: values,
      "What type of internet connection do you need?": que1,
      "Install timeframe required": que2,
      "Please share your address": que3,
      Lead_Generated: timestamp,
    });
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
  };

  return (
    <QuoteLayout>
      <GtagCode />
      <InternetContext.Provider
        value={{
          que1,
          setQue1,
          que2,
          setQue2,
          que3,
          setQue3,
          formValues,
          setFormValues,
          addLeadToBackend,
        }}
      >
        <Router>
          <section path={Product_Quote_Routes.BusinessInternet}>
            <Question1 title="Business Internet" path="/" />
            <Question2 title="Business Internet" path="/2" />
            <Question3 title="Business Internet" path="/3" />
            <Form title="Business Internet" path="/4" />
            <Thankyou title="Business Interne" path="/thankyou" />
          </section>
        </Router>
      </InternetContext.Provider>
      <PrivacySection />
      <ServicesMenuSection />
      <Footer />
    </QuoteLayout>
  );
};

export default Quote;
