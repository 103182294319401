import React from "react";
import { Helmet } from "react-helmet";

export default function GtagCode() {
  return (
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-16556389763"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16556389763');`}
      </script>
    </Helmet>
  );
}
